import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/Layout";
import SupportRoll from "../../components/SupportRoll";

export default class ArticlesIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta name={`robots`} content={`noindex,nofollow`}/>
        </Helmet>
        <section className="section mb-6">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-one-half-desktop is-three-quarters-tablet">
                <div className="block has-text-centered">
                  <h1 className="title is-h1">Support</h1>
                  <h4 className="is-h4">Videos to help pay your employees fairly, painlessly</h4>
                </div>

                <SupportRoll />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
